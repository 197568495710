import {useState, useEffect} from 'react'

/** @jsx jsx */
import {css, jsx} from '@emotion/core'

import axios from 'axios'
import _ from 'lodash'
import {Link, useLocation} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import {gtag} from 'ga-gtag'
import checkPng from '../theme/check.png'
import downCaretPng from '../theme/down_caret.png'

const ServiceRequestPage = (props) => {

	const {authToken, settings} = props

	const [requestItem, setRequestItem] = useState(null)
	const [quantity, setQuantity] = useState(1)
	const [roomNumber, setRoomNumber] = useState('')
	const [guestName, setGuestName] = useState('')
	const [showErrors, setShowErrors] = useState(false)
	const [submitted, setSubmitted] = useState(false)
	const [failed, setFailed] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const location = useLocation()
	const urlSearchParams = new URLSearchParams(location.search)
	const serial = urlSearchParams.get('serial')

	// load content
	useEffect(() => {
		if (!settings || !authToken) return

		// set default option
		if (settings.service_requests) setRequestItem(settings.service_requests.options[0])

	}, [settings, authToken])

	return <div id="content" className="request">
		<div className="content-wrapper">
			<h1>Service Request</h1>

			<div className="form-wrapper">
				{failed && <div className="failure">
					<h1>Sorry, there was a problem with your request.</h1>
					<div>Please try again.</div>
					<button className="ok">OK</button>
				</div>}
				{submitted && <div className="confirmation">
					<img src={checkPng}/>
					<h1>Request Submitted</h1>
					<div>We'll be in contact shortly.</div>
					<button className="ok"><Link to='/'>OK</Link></button>
				</div>}
				{!failed && !submitted && <div className="service-request">
					<label
						className={showErrors && (!roomNumber || roomNumber.trim() === '') ? 'validation-error' : undefined}
					>Room Number <span className={`validation-detail`}>Required</span></label>
					<input type="text" value={roomNumber} placeholder="Please enter your room number"
					       className={`room guest_room ${showErrors && (!roomNumber || roomNumber.trim() === '') && 'validation-error'}`}
					       onChange={(e) => {
						       setRoomNumber(e.target.value)
					       }}
					/>
					<label
						className={showErrors && (!guestName || guestName.trim() === '') ? 'validation-error' : undefined}>Guest
						Name <span className="validation-detail">Required</span></label>
					<input type="text" value={guestName}
					       className={`guest_name ${showErrors && (!guestName || guestName.trim() === '') && 'validation-error'}`}
					       placeholder="Please enter your name"
					       onChange={(e) => {
						       setGuestName(e.target.value)
					       }}
					/>
					<label>Make a Selection</label>
					<div className="service-request-options">
						<select
							className="request_item"
							css={css`
								width: 60%;
								background-image: url(${downCaretPng});
								background-position: calc(100% - 20px) calc(100% - 15px);
								background-repeat: no-repeat;`
							}
							onChange={(e) => {
								setRequestItem(_.find(settings.service_requests.options, {description: e.target.value}))
							}}>
							{settings && settings.service_requests
								? settings.service_requests.options.map((serviceRequestOption, i) => {
									return <option key={i}
									               id={serviceRequestOption.id}>{serviceRequestOption.description}</option>
								})
								: 'loading'}
						</select>
						{requestItem && requestItem.type === 'item' &&
						<div className="spinner">
							<button
								className="decrement"
								onClick={() => {
									setQuantity(Math.max(1, quantity - 1))
								}}
							>&ndash;</button>
							<input
								value={quantity}
								type="number"
								className="request_quantity"
								readOnly="readonly"
							/>
							<button
								className="increment"
								onClick={() => {
									setQuantity(Math.min(requestItem.max || 5, quantity + 1))
								}}
							>+
							</button>
						</div>
						}
					</div>
					<div className="buttons">
						<button className="cancel"><Link to='/'>Cancel</Link></button>
						<div>
							<Button
								variant='contained'
								className="submit"
								css={css`position:relative;`}
								disabled={isLoading}
								onClick={async () => {
									setShowErrors(true)
									let isValid = true
									if (!guestName || guestName === '') {
										isValid = false
									}
									if (!roomNumber || roomNumber === '') {
										isValid = false
									}

									if (!isValid) return

									setIsLoading(true)

									// post
									let response = await axios.post(`${process.env.REACT_APP_KONG_API_URL}/service_request`,
										{
											property_id: settings.property_id,
											serial,
											guest_name: guestName,
											guest_room: roomNumber,
											request_item: requestItem.id,
											request_quantity: quantity,
										},
										{
											headers: {Authorization: `Bearer ${authToken}`},
										})

									// metrics
									gtag('event', 'service_request', {
										property_id: settings.property_id,
										serial: serial,
										guest_name: guestName,
										guest_room: roomNumber,
										request_item: requestItem.id,
										request_quantity: quantity,
									})

									if (response.status === 200) {
										setFailed(false)
										setSubmitted(true)
									} else {
										setFailed(true)
										setSubmitted(false)
									}
								}}
							>
								{isLoading && <CircularProgress size={24} css={css`margin-right:20px;`}/>}
								Submit</Button>
						</div>
					</div>
				</div>}
			</div>
		</div>
	</div>

}

export default ServiceRequestPage