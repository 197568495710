import {useEffect, useState} from 'react'
import {
	Route,
	useLocation
} from 'react-router-dom'

/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import axios from 'axios'
import {Header} from './Header'
import GamesPage from '../pages/GamesPage'
import HomePage from '../pages/HomePage'
import ArticlePage from '../pages/ArticlePage'
import DiningPage from '../pages/DiningPage'
import ServiceRequestPage from '../pages/ServiceRequestPage'
import InfoPage from '../pages/InfoPage'
import ViatorPage from '../pages/ViatorPage'
import GoldstarPage from '../pages/GoldstarPage'
import {ArcAds} from 'arcads'
import gtag, {install} from 'ga-gtag'
import _ from 'lodash'

export const KongWrapper = (props) => {

	const Android = window.Android
	const isAndroid = typeof Android != 'undefined'

	const [settings, setSettings] = useState(null)
	const location = useLocation()
	const urlSearchParams = new URLSearchParams(location.search)
	const [failedAds, setFailedAds] = useState([])

	const [authToken, setAuthToken] = useState(null)
	const [webviewAds, setWebviewAds] = useState(null)

	const handleScroll = (evt) => {
		if (Android && Android.updateCarouselRect) {
			const carousel = document.querySelector('#carousel')
			const rect = carousel ? carousel.getBoundingClientRect() : {top:-1000,right:-1000,bottom:-900,left:-900}
			Android.updateCarouselRect(JSON.stringify(rect))
		}
	}

	// scroll listener
	useEffect(()=>{
		window.addEventListener('scroll', handleScroll)
		return ()=>{
			window.removeEventListener('scroll', handleScroll)
		}
	}, [document])

	// carousel update
	useEffect(()=>{
		if (Android && Android.updateCarouselRect) {
			const carousel = document.querySelector('#carousel')
			const rect = carousel ? carousel.getBoundingClientRect() : {top:-1000,right:-1000,bottom:-900,left:-900}
			Android.updateCarouselRect(JSON.stringify(rect))
		}
	})

	// get webview ads
	useEffect(() => {
		if (!settings || !location) return
		if (settings.webview_ads) {

			// filter to just ads for this page
			const webviewAds = _.filter(settings.webview_ads, (ad)=>{return ad.pagePath.includes(location.pathname)})
			setWebviewAds(webviewAds)
		}
	}, [settings, location])

	// auth token
	useEffect(() => {
		(async () => {
			console.log(`getting auth token... from ${process.env.REACT_APP_KONG_API_URL}/token`)
			// post
			let response = await axios.post(`${process.env.REACT_APP_KONG_API_URL}/token`,
				{
					id: process.env.REACT_APP_KONG_PROPERTY_ID,
					client_key: process.env.REACT_APP_KONG_PROPERTY_CLIENT_KEY
				})

			if (response.status === 200) setAuthToken(response.data)
		})()
	}, [])

	// register ads
	useEffect(() => {

		if (!settings || !webviewAds) return

		console.log('initializing ArcAds...')
		const arcAds = new ArcAds({
			dfp: {
				id: settings.dfp_id,
			},
		}, async (event) => {

			let {isEmpty, slot} = event

			event._adUnitPath = await slot.getAdUnitPath().toString()
			let slotElementID = await slot.getSlotElementId()

			if (isEmpty) {
				// ad wasn't filled, so log a warning and hide the div
				console.warn(event)
				setFailedAds(prevFailedAds => [...prevFailedAds, slotElementID])
				return
			}

			console.log(event)
		})

		console.log('registering ads...')

		webviewAds.map(ad=>{
			console.log(ad)
			arcAds.registerAd(ad)
		})

	}, [settings, webviewAds])

	// load property config
	useEffect(() => {

		if (!authToken || !location) return

		(async () => {

			const propertyID = urlSearchParams.get('id') || process.env.REACT_APP_KONG_PROPERTY_ID

			console.log(`getting settings for ${propertyID}...`)
			let config = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/config?id=${propertyID}`,
				{
					headers: {Authorization: `Bearer ${authToken}`},
				})

			config.data.settings.property_id = config.data.id
			if (config.data.service_requests) config.data.settings.service_requests = config.data.service_requests
			if (config.data.ads) config.data.settings.ads = config.data.ads
			if (config.data.webview_ads) config.data.settings.webview_ads = config.data.webview_ads

			//const { ads, name, service_requests, settings } = config.data
			setSettings(config.data.settings)
		})()

	}, [authToken, location])

	// metrics (TODO: put into its own component)
	useEffect(()=>{

		install(process.env.REACT_APP_GTAG)

		window.dataLayer = window.dataLayer || []

		const urlSearchParams = new URLSearchParams(location.search)
		const serial = urlSearchParams.get('serial')
		const property_id = urlSearchParams.get('id')

		if (serial) gtag('set', 'user_properties', {serial})
		if (property_id) gtag('set', 'user_properties', {property_id})

	}, [location])

	return <div css={css`
		height: 800px;
		width: 1280px;
		margin: 0 auto;
		padding-bottom: 90px;
	`}>
		{!isAndroid && <Header settings={settings} />}
		<Route exact path='/(games|games.html)'>
			<GamesPage settings={settings} authToken={authToken} ads={webviewAds} failedAds={failedAds} ads={webviewAds} />
		</Route>
		<Route exact path='/'>
			<HomePage authToken={authToken} settings={settings} ads={webviewAds} failedAds={failedAds} Android={Android} />
		</Route>
		<Route exact path='/(article|content.html)'>
			<ArticlePage authToken={authToken} settings={settings} ads={webviewAds} failedAds={failedAds} ads={webviewAds} Android={Android} />
		</Route>
		<Route exact path='/(dining|dining.html)'>
			<DiningPage authToken={authToken} settings={settings} ads={webviewAds} failedAds={failedAds} />
		</Route>
		<Route exact path='/(request|request.html)'>
			<ServiceRequestPage authToken={authToken} settings={settings} />
		</Route>
		<Route exact path='/(info.html|info)'>
			<InfoPage authToken={authToken} settings={settings} failedAds={failedAds} ads={webviewAds} Android={Android} />
		</Route>
		<Route exact path='/(viator.html|viator)'>
			<ViatorPage authToken={authToken} settings={settings}/>
		</Route>
		<Route exact path='/(goldstar.html|goldstar)'>
			<GoldstarPage authToken={authToken} settings={settings}/>
		</Route>
		<div id="tos-footer">
			<h1><a href="https://intelity.com/terms-of-service/">Terms of Service &amp; Privacy Policy</a></h1>
			<hr/>
			<span>&copy; {new Date().getFullYear()} INTELITY | All rights reserved</span>
		</div>
	</div>
}