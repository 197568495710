import {useState, useEffect} from 'react'

/** @jsx jsx */
import {css, jsx} from '@emotion/core'

import axios from 'axios'

import {Skeleton} from '@material-ui/lab'
import _ from 'lodash'
import {useLocation} from 'react-router-dom'
import {FooterHeadlines} from '../components/FooterHeadlines'
import {SurveyLink} from '../components/SurveyLink'
import {TopHeadlines} from '../components/TopHeadlines'
import {AdGroup} from '../components/AdGroup'
import {YouMightAlsoLike} from '../components/YouMightAlsoLike'
import {ThumborImage} from '../components/ThumborImage'

const ArticlePage = (props) => {

	const {authToken, settings, failedAds, ads, Android} = props

	const [headlines, setHeadlines] = useState(null)
	const [article, setArticle] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const location = useLocation()
	const key = new URLSearchParams(location.search).get('key')

	useEffect(() => {
		if (!settings || !key ) return
		(async ()=>{
			setIsLoading(true)
			const requestedArticle = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/cache/${key}`,
				{
					headers: {Authorization: `Bearer ${authToken}`},
				})

			setArticle(requestedArticle.data)
			setIsLoading(false)
		})()
	}, [settings, key])

	// load content
	useEffect(() => {
		if (!settings || !authToken) return

		(async () => {

			const topHeadlinesSlots = 5
			const footerSlots = 3
			const totalSlots = topHeadlinesSlots + footerSlots

			let adItems = settings.ads || []

			let feed = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/cache/feed/${totalSlots}`,
				{
					headers: {Authorization: `Bearer ${authToken}`},
				})

			let feedItems = feed.data.items

			let topHeadlineAds = _.filter(adItems, {'slot': 'top-headlines'})
			let footerAds = _.filter(adItems, {'slot': 'footer'})

			setHeadlines({
				topHeadlineItems: _.concat(topHeadlineAds, feedItems.splice(0, topHeadlinesSlots - topHeadlineAds.length)),
				footerItems: _.concat(footerAds, feedItems.splice(0, footerSlots - footerAds.length)),
			})

		})()

	}, [settings, authToken])

	return <div>

		<div id="content">
			{!isLoading && article
				? <ThumborImage className='header' component='div' width={1280} height={470} src={article.image} authToken={authToken}><div className="tag">
					{article.source.label}
				</div></ThumborImage>
				: <div className="header">
					<div className="tag">
						<Skeleton variant='text' width={150} />
					</div>
				</div>
			}
			<div className="content-wrapper">
				<div className="left">
					<div className="date">
						{!isLoading && article
							? article.textDate
							: <Skeleton variant='text' width={150} />
						}
					</div>
					<h1 id="article-title">
						{!isLoading && article
							? article.title
							: <Skeleton variant='text' width={150} />
						}
					</h1>
					<div className="tag">
						{!isLoading && article
							? article.source.label
							: <Skeleton variant='text' width={150} />
						}
					</div>
					<div id="article-content">
						{!isLoading && article
							? article.content
							: <p>
								<Skeleton />
								<Skeleton />
								<Skeleton />
							</p>
						}
					</div>
					{!isLoading && article
						? <a id="read-more" href={article.url}>&mdash; READ More on <span className="tag">SOURCE</span> &mdash;</a>
						: <Skeleton variant='text' />
					}
				</div>
				<div className="right">
					<TopHeadlines headlines={headlines} />
					<AdGroup failedAds={failedAds} ads={ads} orientation='vertical' />
				</div>
				<div className="clearfix"/>
				<AdGroup failedAds={failedAds} ads={ads} orientation='horizontal' />
				<YouMightAlsoLike/>
				<FooterHeadlines headlines={headlines} authToken={authToken}/>
			</div>
		</div>

		<SurveyLink settings={settings} Android={Android} />
	</div>
}

export default ArticlePage