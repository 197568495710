import {useState, useEffect} from 'react'

/** @jsx jsx */
import {css, jsx} from '@emotion/core'

import axios from 'axios'

import {Link} from 'react-router-dom'
import Skeleton from '@material-ui/lab/Skeleton'
import viatorHeroPng from '../theme/viator-hero.png'
import starRatingSprite from '../theme/star-rating-sprite.png'
import emptyStateSvg from '../theme/empty-state.svg'

const ViatorPage = (props) => {

	const {authToken, settings} = props

	const [isLoading, setIsLoading] = useState(true)
	const [attractions, setAttractions] = useState(null)
	const [totalAttractionsToShow, setTotalAttractionsToShow] = useState(8)

	// load content
	useEffect(() => {
		if (!settings || !authToken) return

		(async () => {

			let response = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/viator/attractions/${settings.property_id}`, {
				headers: {Authorization: `Bearer ${authToken}`},
			})

			let {data} = response
			if (data) {
				setAttractions(response.data)
			} else {
				setAttractions([])
			}
			setIsLoading(false)
		})()

	}, [settings, authToken])

	return <div id="content" className="viator">
		<div className="header" css={css`
			background-image: url(${viatorHeroPng});
			height: 360px !important;
			background-repeat: no-repeat;
		`}/>
		<div className="content-wrapper">
			<div className="title">Viator</div>
			<div className="tagline">Discover amazing things to do</div>
			<div className="viator-items">

				{isLoading && [...Array(8)].map((item,i)=>{
					return <div className='viator-item' key={i}>
						<Skeleton variant='rect' width={240} height={130} />
						<h4><Skeleton variant='text' /></h4>
						<h4><Skeleton variant='text' /></h4>
					</div>
				})}

				{attractions && attractions.length > 0 && attractions.map((attraction, i)=>{

					if (i >= totalAttractionsToShow) return

					return <div className="viator-item" key={i}>
						<a href={attraction.webURL}>
							<img src={attraction.thumbnailHiResURL} className="thumb" alt={attraction.title} />
								<h4>{attraction.title}</h4>
								<div css={css`
									float: left;
									background: url(${starRatingSprite}) repeat-x;
									font-size: 0;
									height: 12px;
									line-height: 0;
									overflow: hidden;
									text-indent: -999em;
									width: 75px;
									margin-top: 4px;
				
									&-rating {
										background: url(${starRatingSprite}) repeat-x 0 100%;
										height: 12px;
										display: block;
									}
								`}>
									<span css={css`width:${(attraction.rating / 5) * 100}%`} className="star-ratings-sprite-rating" />
								</div>
						</a>
					</div>
				})}
			</div>

			{!attractions || attractions.length === 0 && <div className="no-results">
				<h1>No Results Found</h1>
				<p>It seems we can't find any results based on your search.</p>
				<img src={emptyStateSvg}/>
				<Link className="go-back-home" to="/">Go Back Home</Link>
			</div>}

			<div className="clearfix" />

			{attractions && totalAttractionsToShow < attractions.length &&
			<a
				className="view-more"
				href='#'
				onClick={(e) => {
					e.preventDefault()
					setTotalAttractionsToShow(totalAttractionsToShow + 8)
				}}
			>View More</a>
			}

		</div>
	</div>

}


export default ViatorPage