/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {Link} from 'react-router-dom'
import {ThumborImage} from './ThumborImage'

export const FeaturedHeadline = (props) => {

	const {article, authToken} = props

	const isAd = !article.source
	if(isAd) {
		return <div>
			<a href={article.url}>
				<ThumborImage
					src={article.image}
					width={540}
					height={300}
					component='article'
					authToken={authToken}
				>
					<span>{article.label}</span>
					<h3>{article.title}</h3>
				</ThumborImage>
			</a>
		</div>
	} else {
		return <div>
			<Link to={`/article?key=${article.key}`}>
				<ThumborImage
					src={article.image}
					width={540}
					height={300}
					component='article'
					authToken={authToken}
				>
					<span>{article.source.label}</span>
					<h3>{article.title}</h3>
				</ThumborImage>
			</Link>
		</div>
	}
}