import React, {useState, useEffect} from 'react'

import axios from 'axios'

import {Skeleton} from '@material-ui/lab'
import _ from 'lodash'
import {AdGroup} from '../components/AdGroup'
import {YouMightAlsoLike} from '../components/YouMightAlsoLike'
import {FooterHeadlines} from '../components/FooterHeadlines'

const GamesPage = (props) => {

	const {authToken, settings, failedAds, ads} = props

	const [headlines, setHeadlines] = useState(null)
	const [games, setGames] = useState(null)
	const [gamesCategory, setGamesCategory] = useState('All')
	const [totalGamesToShow, setTotalGamesToShow] = useState(15)

	// load content
	useEffect(() => {
		if (!settings || !authToken) return

		(async () => {

			const footerSlots = 3
			const totalSlots = footerSlots

			let adItems = settings.ads || []

			console.log('getting content...')
			let feed = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/cache/feed/${totalSlots}`,
				{
					headers: {Authorization: `Bearer ${authToken}`},
				})

			let feedItems = feed.data.items

			let footerAds = _.filter(adItems, {'slot': 'footer'})

			setHeadlines({
				footerItems: _.concat(footerAds, feedItems.splice(0, footerSlots - footerAds.length)),
			})

		})()

	}, [settings, authToken])

	// load games
	useEffect(() => {
		if (!settings) return
		(async () => {
			console.log('getting games...')
			const gamesResponse = await axios.get(`https://games.gamepix.com/games?sid=${settings.gamepix_id}`)
			const games = filterGames(gamesResponse.data.data)
			setGames(games)
		})()
	}, [settings])

	const catGames = (gamesCategory.toLocaleLowerCase() === 'all')
		? games
		: _.filter(games, {category: gamesCategory})

	return <div id="content" className="games">
		<div className="content-wrapper">

			<AdGroup settings={settings} orientation='horizontal' failedAds={failedAds} ads={ads}/>

			<div id="article-content">

				<ul id="categories">
					{games
						? getGameCategories(games).map((cat, i) => {
							return <a
								key={i}
								href='#'
								className={gamesCategory && gamesCategory === cat ? 'active' : undefined}
								onClick={() => {
									setGamesCategory(cat)
								}}>{cat}</a>
						})
						: [...Array(9)].map((_, i) => {
							return <Skeleton key={i} variant='rect' width={120} height={30} component='a'/>
						})
					}
				</ul>

				<div className="left w-800">
					<div id="games">
						{games && gamesCategory
							? (() => {
							return <div className='game-row'>
								{catGames.map((game, gameIndex) => {
									// limit number of games to display
									if (gameIndex >= totalGamesToShow) return
									// render games
									return <div key={gameIndex}>
										<a href={game.url}>
											<img src={game.thumbnailUrl100}/>
											<h1>{game.title}</h1>
										</a>
									</div>
								})}
							</div>
						})()
							: <div className='game-row'>
								{[...Array(totalGamesToShow)].map((_, i) => {
									return <div key={i}>
										<Skeleton variant='rect' width={100} height={100}/>
										<Skeleton variant='text' component='h1'/>
									</div>
								})}
							</div>
						}
					</div>
					{catGames && totalGamesToShow && (totalGamesToShow < catGames.length) &&
					<a className="view-more" href="#" onClick={(e)=>{
						e.preventDefault()
						setTotalGamesToShow(totalGamesToShow+10)
					}}>View More</a>}
				</div>

				<div className="right">
					<AdGroup settings={settings} orientation='vertical' failedAds={failedAds} ads={ads}/>
				</div>
			</div>

			<div className='clearfix'/>

			<YouMightAlsoLike/>
			<FooterHeadlines headlines={headlines} authToken={authToken}/>

			<footer>
			</footer>

		</div>
	</div>

}

function filterGames(games) {
	// remove games that don't support touch (using filter)
	games = _.reject(games, game =>
		// reject games that don't support touch
		!game.touch
		// reject games that don't support landscape
		|| game.orientation !== 'landscape',
	)

	// by default, sort by score
	games = _.sortBy(games, game => {
		return parseInt(game.rkScore) || 0
	}).reverse()

	return games
}

function getGameCategories(games) {
	let categories = []

	_.forOwn(_.groupBy(games, 'category'), (v, k) => {
		categories.push(k)
	})
	categories = _.orderBy(categories, c => c)

	// make sure 'All' is the first item
	categories.unshift('All')
	return categories
}


export default GamesPage