/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {Skeleton} from '@material-ui/lab'
import _ from 'lodash'

export const AdGroup = (props) => {

	const {failedAds, orientation = 'horizontal', ads} = props

	return <div>
		{_.filter(ads,{orientation}).map((ad,i)=>{
			if (failedAds.includes(ad.id)) return
			return <Skeleton variant='rect' component='div' id={ad.id} key={i}/>
		})}
	</div>
}