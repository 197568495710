/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {Skeleton} from '@material-ui/lab'
import {Link, useLocation} from 'react-router-dom'
import {IconMenu} from './IconMenu'
import arrowPng from '../theme/arrow.png'

export const Header = (props) => {

	const {settings} = props
	const location = useLocation()

	return <header>
		{location.pathname !== '/' &&
		<div className='back'
		     css={css`
				float: left;
				width: 60px;
				height: 100%;
				object-fit: contain;
				background-image: url(${arrowPng});
				background-position: center center;
			`}
		     onClick={()=>{
			     window.history.back()
		     }}
		/>}
		<Link to={'/'}>
			{settings
				? <div id='logo' css={css`background-image: url(${settings.theme.logo})`}/>
				: <Skeleton variant='rect' component='div' id='logo'/>
			}
		</Link>
		<IconMenu settings={settings} />
	</header>
}