/** @jsx jsx */
import {css, jsx} from '@emotion/core'

export const YouMightAlsoLike = (props) => {

	return <h1
		css={css`
			width: 100%;
			font-family: Roboto,sans-serif;
			clear: both;
			text-align: center;
			margin: 60px 0 40px;
			font-size: 20px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: 2.21;
			letter-spacing: 0.56px;
			color: #1976d2;
			text-transform: uppercase;
		`}
	>You Might Also Like</h1>
}