/** @jsx jsx */
import {css, jsx} from '@emotion/core'

import surveymonkeyBanner from '../theme/surveymonkey-banner.png'

export const SurveyLink = (props) => {

	const {settings, Android} = props

	return <div id="survey-footer" className="survey" css={css`
		background: url(${surveymonkeyBanner}) no-repeat;
		background-size: contain;
		height: 150px;
		width: 100%;
		margin-bottom: 180px;
	`}>
		<h4 css={css`
			color: #fff;
			font-size: 36px;
			line-height: 1.44;
			letter-spacing: 1px;
			font-weight: 500;
			text-align: center;
			height:52px;
			margin: auto;
			padding-top: 50px;
		`}>We'd love to hear from you</h4>
		<a
			href={settings && settings.survey_url}
			className="leave-feedback"
			css={css`
				display:block;
				position:relative;
				z-index: 1;
				top: 70px;
				margin: 0 auto;
				padding: 22px 0 22px;
				width: 315px;
				background-color: #00bf6f;
				color: #fff;
				text-transform: uppercase;
				text-align: center;
				font-size: 16px;
				font-weight: bold;
			`}
			onClick={(evt)=>{
				if (Android) {
					evt.preventDefault()
					Android.onSurveyClick()
				}
			}}
		>Leave Feedback</a>
	</div>

}