/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import axios from 'axios'
import {useEffect, useState} from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

export const ThumborImage = (props) => {

	const {src, width, height, authToken, component, children, className} = props

	const [isLoading, setIsLoading] = useState(true)
	const [thumborImage, setThumborImage] = useState(null)

	useEffect(()=>{

		(async()=>{
			//console.log(`loading thumbor image for ${src}`)
			// thumbor
			const apiResponse = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/img?width=${width}&height=${height}&path=${encodeURIComponent(src)}`,
				{
					headers: {Authorization: `Bearer ${authToken}`},
				})

			if (apiResponse.status === 200) {
				setThumborImage(apiResponse.data)
			} else {
				//console.error(`could not generate image for ${src}`)
				console.log(apiResponse)
				setThumborImage(src)
			}

			setIsLoading(false)
		})()

	}, [src, width, height])

	if (!isLoading) {
		if (!component || component === 'img') {
			return <img src={thumborImage} className={className} />
		} else if (component === 'div') {
			return <div css={css`background-image:url(${thumborImage})`} className={className}>{children}</div>
		} else if (component === 'article') {
			return <article css={css`background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.55) 63%, #000000), url(${thumborImage})`} className={className}>
				{children}
			</article>
		}
	} else {
		return <Skeleton variant='rect' width={width} height={height} className={className} component={component}/>
	}
}