/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {Link} from 'react-router-dom'
import {Skeleton} from '@material-ui/lab'
import {ThumborImage} from './ThumborImage'

export const FooterHeadlines = (props) => {

	const {headlines, authToken} = props

	return <footer>
		{headlines
			? headlines.footerItems.map((item, i) => {

				const isAd = !item.source

				if (isAd) {
					return <a key={i} href={item.url}>
						<article>
							<ThumborImage component='div' className='thumb' authToken={authToken} width={340}
							              height={196} src={item.image}/>
							<h3>{item.title}</h3>
							<p>{item.description}</p>
						</article>
					</a>
				} else {
					return <Link key={i} to={`/article?key=${item.key}`}>
						<article>
							<ThumborImage component='div' className='thumb' authToken={authToken} width={340}
							              height={196} src={item.image}/>
							<h3>{item.title}</h3>
							<p>{item.contentSnippet}</p>
						</article>

					</Link>
				}
			})
			: [...Array(3)].map((item, i) => {
				return <a key={i}>
					<article>
						<Skeleton variant='rect' width={340} height={196} component='div' className='thumb'/>
						<h3>
							<Skeleton variant='text'/>
							<Skeleton variant='text'/>
							<Skeleton variant='text'/>
						</h3>
						<p>
							<Skeleton variant='text'/>
							<Skeleton variant='text'/>
							<Skeleton variant='text'/>
						</p>
					</article>
				</a>
			})
		}
	</footer>
}