/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {Link} from 'react-router-dom'
import {Skeleton} from '@material-ui/lab'

export const TopHeadlines = (props) => {

	const {headlines} = props

	return <aside>
		<h1
			css={css`
						margin: 17px 0 0 19px !important;
						font-family: Roboto,sans-serif;
						text-transform: uppercase;
						font-size: 14px;
						font-weight: bold;
						letter-spacing: 1.53px;
						color: #1976d2;
					`}
		>Top Headlines</h1>
		<ul id="top-headlines">
			{headlines
				? headlines.topHeadlineItems.map((item,i) => {

					const isAd = !item.source
					if (isAd) {
						return <li key={i}><a href={item.url}>{item.title}</a></li>
					} else {
						return <li key={i}><Link to={`/article?key=${item.key}`}>{item.title}</Link></li>
					}
				})
				: [...Array(5)].map((item,i) => { return <li key={i}>
					<a>
						<Skeleton/>
						<Skeleton/>
						<Skeleton/>
					</a>
				</li> })
			}
		</ul>
	</aside>

}