import {useState, useEffect} from 'react'

/** @jsx jsx */
import {css, jsx} from '@emotion/core'

import axios from 'axios'

import {Skeleton} from '@material-ui/lab'
import _ from 'lodash'
import {useLocation} from 'react-router-dom'
import {FooterHeadlines} from '../components/FooterHeadlines'
import {SurveyLink} from '../components/SurveyLink'
import {TopHeadlines} from '../components/TopHeadlines'
import {AdGroup} from '../components/AdGroup'
import {YouMightAlsoLike} from '../components/YouMightAlsoLike'
import moment from 'moment'

const InfoPage = (props) => {

	const {authToken, settings, failedAds, ads, Android} = props

	const [headlines, setHeadlines] = useState(null)

	// load content
	useEffect(() => {
		if (!settings || !authToken) return

		(async () => {

			const topHeadlinesSlots = 5
			const footerSlots = 3
			const totalSlots = topHeadlinesSlots + footerSlots

			let adItems = settings.ads || []

			let feed = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/cache/feed/${totalSlots}`,
				{
					headers: {Authorization: `Bearer ${authToken}`},
				})

			let feedItems = feed.data.items

			let topHeadlineAds = _.filter(adItems, {'slot': 'top-headlines'})
			let footerAds = _.filter(adItems, {'slot': 'footer'})

			setHeadlines({
				topHeadlineItems: _.concat(topHeadlineAds, feedItems.splice(0, topHeadlinesSlots - topHeadlineAds.length)),
				footerItems: _.concat(footerAds, feedItems.splice(0, footerSlots - footerAds.length)),
			})

		})()

	}, [settings, authToken])

	if (!settings) return <div>
		<div id="content">
			<div className="header">
				<div className="date">{moment().format('MMMM DD, YYYY')}</div>
				<h1><Skeleton/></h1>
				<h2><Skeleton/></h2>
			</div>
			<div className="content-wrapper">
				<div className="left">
					<div id="article-content">
						<div>
							<p>
								<Skeleton/>
								<Skeleton/>
								<Skeleton/>
							</p>
						</div>
					</div>
				</div>
				<div className="right">
					<TopHeadlines />
					<AdGroup failedAds={failedAds} orientation='vertical' />
				</div>
				<div className="clearfix"/>
				<AdGroup failedAds={failedAds} orientation='horizontal' />
				<YouMightAlsoLike/>
				<FooterHeadlines/>
			</div>
		</div>

		<SurveyLink />
	</div>

	return <div>

		<div id="content">
			<div className="header" css={
				settings
					? css`background-image: url(${settings.theme.image})`
					: css`background-image: none`
			}>
				<div className="date">{moment().format('MMMM DD, YYYY')}</div>
				<h1>{settings.theme.welcome_title}</h1>
				<h2>{settings.theme.title}</h2>
			</div>
			<div className="content-wrapper">
				<div className="left">
					<div id="article-content">
						<div dangerouslySetInnerHTML={{__html:settings.theme.welcome_body}} />
					</div>
				</div>
				<div className="right">
					<TopHeadlines headlines={headlines} />
					<AdGroup failedAds={failedAds} orientation='vertical' ads={ads} />
				</div>
				<div className="clearfix"/>
				<AdGroup failedAds={failedAds} orientation='horizontal' ads={ads} />
				<YouMightAlsoLike/>
				<FooterHeadlines headlines={headlines} authToken={authToken}/>
			</div>
		</div>

		<SurveyLink settings={settings} Android={Android} />
	</div>
}

export default InfoPage