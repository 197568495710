import {useEffect, useState} from 'react'
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom'
import './App.scss'

/** @jsx jsx */
import {css, jsx} from '@emotion/core'

import {KongWrapper} from './components/KongWrapper'

function App() {
	return (
		<Router>
			<Route component={ScrollToTop} />
			{/* Page Wrapper */}
			<KongWrapper />
		</Router>
	)
}

const ScrollToTop = () => {
	window.scrollTo({top:0, behavior: 'smooth'})
	return null
}

export default App
