import {useState, useEffect} from 'react'

/** @jsx jsx */
import {css, jsx} from '@emotion/core'

import axios from 'axios'

import {Skeleton} from '@material-ui/lab'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {FooterHeadlines} from '../components/FooterHeadlines'
import {SurveyLink} from '../components/SurveyLink'
import {TopHeadlines} from '../components/TopHeadlines'
import {AdGroup} from '../components/AdGroup'
import {YouMightAlsoLike} from '../components/YouMightAlsoLike'
import {FeaturedHeadline} from '../components/FeaturedHeadline'
import {ThumborImage} from '../components/ThumborImage'
import {gtag} from 'ga-gtag'

const HomePage = (props) => {

	const {authToken, settings, failedAds, Android, ads} = props

	const [headlines, setHeadlines] = useState(null)

	// load content
	useEffect(() => {
		if (!settings || !authToken) return

		(async () => {

			const topHeadlinesSlots = 5
			const subCarouselSlots = 3
			const featuredSlots = 2
			const subFeaturedSlots = 3
			const footerSlots = 3
			const totalSlots = topHeadlinesSlots + subCarouselSlots + featuredSlots + subFeaturedSlots + footerSlots

			let adItems = settings.ads || []

			console.log('getting content...')
			let feed = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/cache/feed/${totalSlots}`,
				{
					headers: {Authorization: `Bearer ${authToken}`},
				})

			let feedItems = feed.data.items

			let topHeadlineAds = _.filter(adItems, {'slot': 'top-headlines'})
			let subCarouselAds = _.filter(adItems, {'slot': 'sub-carousel'})
			let featuredAds = _.filter(adItems, {'slot': 'featured'})
			let subFeaturedAds = _.filter(adItems, {'slot': 'sub-featured'})
			let footerAds = _.filter(adItems, {'slot': 'footer'})

			setHeadlines({
				topHeadlineItems: _.concat(topHeadlineAds, feedItems.splice(0, topHeadlinesSlots - topHeadlineAds.length)),
				subCarouselItems: _.concat(subCarouselAds, feedItems.splice(0, subCarouselSlots - subCarouselAds.length)),
				featuredItems: _.concat(featuredAds, feedItems.splice(0, featuredSlots - featuredAds.length)),
				subFeaturedItems: _.concat(subFeaturedAds, feedItems.splice(0, subFeaturedSlots - subFeaturedAds.length)),
				footerItems: _.concat(footerAds, feedItems.splice(0, footerSlots - footerAds.length)),
			})

		})()

	}, [settings, authToken])

	// metrics
	useEffect(() => {
		if (!headlines) return
		Object.keys(headlines).map(section => {
			console.log(`parsing ${section} headlines...`)
			headlines[section].map(item => {

				console.log(`logging webview_impression for ${item.url}`)

				const isAd = !item.source

				if (isAd) {
					gtag('event', 'webview_impression', {
						url: item.url,
						image: item.image,
						title: item.title,
						label: item.label,
						content: item.description,
					})
				} else {
					gtag('event', 'webview_impression', {
						url: `article?key=${item.key}`,
						image: item.image,
						title: item.title,
						label: item.source.label,
						content: item.contentSnippet,
					})
				}
			})
		})
	}, [headlines])

	return <div>
		<div id="top">
			<TopHeadlines headlines={headlines}/>
			<Skeleton variant='rect' component='div' id="carousel"/>
			<section id="sub-carousel">
				{headlines
					? headlines.subCarouselItems.map((item, i) => {

						const isAd = !item.source
						if (isAd) {
							return <a href={item.url} key={i}>
								<article>
									<ThumborImage width={240} height={130} src={item.image} className='img'
									              component='div' authToken={authToken}/>
									<h1>{item.title}</h1>
								</article>
							</a>
						} else {
							return <Link to={`/article?key=${item.key}`} key={i}>
								<article>
									<ThumborImage width={240} height={130} src={item.image} className='img'
									              component='div' authToken={authToken}/>
									<h1>{item.title}</h1>
								</article>
							</Link>
						}
					})
					: [...Array(3)].map((item, i) => {
						return <a key={i}>
							<Skeleton variant='rect' width={240} height={130}/>
							<Skeleton variant='text' component='h1'/>
							<Skeleton variant='text' component='h1'/>
							<Skeleton variant='text' component='h1'/>
						</a>
					})
				}
			</section>
		</div>
		<div id="bottom">
			<ul id="categories">
			</ul>
			<section id="featured">
				{headlines
					? headlines.featuredItems.map((item, i) => {
						return <FeaturedHeadline article={item} authToken={authToken} key={i}/>
					})
					: [...Array(2)].map((item, i) => {
						return <a key={i}>
							<Skeleton variant='rect' width={540} height={300}/>
						</a>
					})
				}
			</section>
			<section id="sub-featured">
				{headlines
					? headlines.subFeaturedItems.map((item, i) => {

						const isAd = !item.source

						if (isAd) {
							return <a href={item.url} key={i}>
								<article>
									<ThumborImage width={240} height={214} src={item.image} className='thumb'
									              component='div' authToken={authToken}/>
									<span className="category">{item.label}</span>
									<h3>{item.title}</h3>
									<p>{item.description}</p>
								</article>
							</a>
						} else {
							return <Link to={`/article?key=${item.key}`} key={i}>
								<article>
									<ThumborImage width={240} height={214} src={item.image} className='thumb'
									              component='div' authToken={authToken}/>
									<span className="category">{item.source.label}</span>
									<h3>{item.title}</h3>
									<p>{item.contentSnippet}</p>
								</article>
							</Link>
						}


					})
					: [...Array(3)].map((item, i) => {
						return <a key={i}>
							<article>
								<Skeleton variant='rect' width={240} height={214} component='div' className='thumb'/>
								<p>
									<Skeleton variant='text' width={100}/>
								</p>
								<p>
									<Skeleton variant='text'/>
									<Skeleton variant='text'/>
									<Skeleton variant='text'/>
								</p>
								<p>
									<Skeleton variant='text'/>
									<Skeleton variant='text'/>
									<Skeleton variant='text'/>
								</p>
							</article>
						</a>
					})
				}
			</section>
			<AdGroup failedAds={failedAds} orientation='vertical' ads={ads}/>
			<div className="clearfix"/>
			<AdGroup failedAds={failedAds} orientation='horizontal' ads={ads}/>
			<YouMightAlsoLike/>
			<FooterHeadlines headlines={headlines} authToken={authToken} ads={ads}/>
		</div>

		<SurveyLink settings={settings} Android={Android}/>
	</div>
}

export default HomePage