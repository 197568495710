/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {Skeleton} from '@material-ui/lab'
import {Link} from 'react-router-dom'

export const IconMenu = (props) => {

	const {settings} = props

	return <ul id="icon-menu">
		{settings
			? settings.icon_menu.map((item, i)=> {

				// is link relative or absolute?
				if (item.link.startsWith('http')) return <li key={i}>
					<a href={item.link}>
						<div
							className="icon"
							css={css`background: rgba(0, 0, 0, 0) url(${item.icon}) no-repeat scroll 0% 0% / cover;`}
						/>
						<span>{item.title}</span></a></li>

				return <li key={i}>
					<Link to={item.link}>
						<div
							className="icon"
							css={css`background: rgba(0, 0, 0, 0) url(${item.icon}) no-repeat scroll 0% 0% / cover;`}
						/>
						<span>{item.title}</span></Link></li>
			})
			: [...Array(6)].map((_, i) => { return placeholderIconMenuItem(i) })
		}
	</ul>
}


const placeholderIconMenuItem = (i) =>
	<li key={i}>
		<a>
			<Skeleton variant='rect' width={44} height={44} component='div' className='icon' />
			<Skeleton variant='text' component='span' />
		</a>
	</li>