/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import diningHeroPng from '../theme/dining-hero.png'
import mapMarkerSvg from '../theme/map-marker.svg'

import {Skeleton} from '@material-ui/lab'

const DiningPage = (props) => {

	const {settings} = props

	return <div id="content" className="dining">
		<div className="header" css={css`
			background-image: url(${diningHeroPng});
			height: 170px !important;			
			padding:0 !important;
		`}>
			<div className="location" css={css`
				background-image: url(${mapMarkerSvg});
				background-position: left center;
				background-size: 14px 20px;
				width:100%;
				padding: 10px 0 0 30px;
				margin:10px 0 0 100px;
			`}>
				{settings
					? <span css={css`
						position: relative;
						top: -4px;
						font-family: Roboto, sans-serif;
						font-weight: 500;
						font-size:14px;
						letter-spacing: 2.33px;
					`}>{settings && `${settings.address.street}, ${settings.address.city}, ${settings.address.state} ${settings.address.zipcode}`}</span>
					: <Skeleton component='span'/>
				}
			</div>
			<hr/>
			{settings
				?
					<h1 css={css`
						font-family: Roboto,sans-serif;
						font-size: 48px;
						line-height: normal;
						letter-spacing: 1.33px;
						color: #ffffff;
						margin: 0 0 0 100px !important;
					`}>{settings && settings.dining.title}</h1>
				: <Skeleton component='h1'/>
			}
			{settings
				?
				<h2 css={css`
					font-size: 14px;
					line-height: normal;
					letter-spacing: 1.75px;
					color: #ffffff;
					margin:10px 0 0 100px;
				`}>{settings && settings.dining.subtitle}</h2>
				: <Skeleton component='h2'/>
			}
		</div>
		<div className="content-wrapper" css={css`margin: 0 !important;`}>

			<div id="article-content" css={css`
				> div {
					padding: 46px 140px;
					text-align: center;

					&:first-child {

						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;

						img {
							flex: 1;
						}
					}

					&:nth-child(odd) {
						background: #ebeff1;
					}
				}
			`}>

				<div className="partners">
					{settings
						? settings.dining.partners.map((partner)=>{
							return <a href={partner.link}>
								<img src={partner.thumb}/>
							</a>
						})
						: [...Array(2)].map( (item,i) => {
							return <a key={i}>
								<Skeleton variant='rect' width={500} height={450}/>
							</a>
						})
					}
				</div>

				{settings
					? settings.dining.partners.map((partner,i) => {
						return <div className="partner" key={i}>
							<a href={partner.link}>
								<img src={partner.banner}/>
							</a>
						</div>
					})
					: [...Array(2)].map( (item,i) => {
						return <div className="partner" key={i}>
							<a>
								<Skeleton variant='rect' width={1000} height={450}/>
							</a>
						</div>
					})
				}
			</div>

		</div>
	</div>
}

export default DiningPage