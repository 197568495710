import {useState, useEffect} from 'react'

/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import goldstarHeroJpg from '../theme/goldstar-hero.jpg'

import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'

import Skeleton from '@material-ui/lab/Skeleton'

const GoldstarPage = (props) => {

	const {authToken, settings} = props

	const [isLoading, setIsLoading] = useState(true)
	const [listings, setListings] = useState(null)
	const [totalListingsToShow, setTotalListingsToShow] = useState(8)

	// load content
	useEffect(() => {
		if (!settings || !authToken) return

		(async () => {
			let response = await axios.get(`${process.env.REACT_APP_KONG_API_URL}/goldstar/listings/${settings.address.zipcode}?limit=144`, {
				headers: {Authorization: `Bearer ${authToken}`},
			})

			let {data} = response

			if (data) {
				setListings(_.sortBy(data, [function(o) { return o.upcoming_dates[0].date_and_time_iso8601 }]))
			} else {
				setListings([])
			}
			setIsLoading(false)
		})()

	}, [settings, authToken])

	return <div id="content" className="goldstar">
		<div className="header" css={css`
			background-image: url(${goldstarHeroJpg});
			height: 360px !important;
			background-repeat: no-repeat;
		`}/>
		<div className="content-wrapper">
			<div className="title">Goldstar</div>
			<div className="tagline">Find Discount Tickets to Events Near You</div>

			{isLoading && <div className="goldstar-items">
				<div className='goldstar-row'>
					{[...Array(8)].map((item,i)=>{
						return <div className='goldstar-item' key={i}>
							<a>
								<Skeleton variant='rect' width={240} height={130} />
								<h4><Skeleton variant='text'/></h4>
								<h5><Skeleton variant='text'/></h5>
								<p><Skeleton variant='text'/></p>
							</a>
						</div>
					})}
				</div>
			</div>}

			{listings && listings.length > 0 &&
			<div className="goldstar-items">
				<div className='goldstar-row'>
				{listings.map((listing, i)=>{

					if (i >= totalListingsToShow) return

					const venueName = listing.venue.name
					const venueLocation = `${listing.venue.address.locality}, ${listing.venue.address.region}`
					const isOnline = !listing.venue.address.locality && !listing.venue.address.region

					return <div className="goldstar-item">
						<a href={listing.link}>
							<div className="thumb-wrapper">
								<div className="date">{moment(listing.upcoming_dates[0].date_and_time_iso8601).format('ddd, MMM D')}</div>
								<img src={listing.image} className="thumb" alt={listing.title_as_text}/>
							</div>
							<h4>{listing.title_as_text}</h4>
							<h5>{listing.venue.name}</h5>
							{!isOnline && <h5>{venueLocation}</h5>}
							<p>{listing.headline_as_text}</p>
						</a>
					</div>
				})}
				</div>
			</div>}
			<div className="clearfix"/>
			{listings && totalListingsToShow && totalListingsToShow < listings.length &&
			<a className="view-more" href="#"
				onClick={(e)=>{
					e.preventDefault()
					setTotalListingsToShow(totalListingsToShow+8)
				}}
			>View More</a>}
		</div>
	</div>

}


export default GoldstarPage